import { SagaReturnType, put, takeEvery, takeLatest, call } from "redux-saga/effects";
// Types
import { AnyAction } from "@reduxjs/toolkit";
import { TEmailBuyerPayload } from "./types";
// Utils
import { emailBuyerRequestType as reqType } from "@features/EmailBuyer/utils/constants";
// Actions
import { emailBuyerRejected, emailBuyerResolved } from "../../slice";
// Handlers
import { handleSendEmail } from "../handlers";

function* sendEmail({ emails }: TEmailBuyerPayload & AnyAction) {
   try {
      const res: SagaReturnType<typeof handleSendEmail> = yield call(handleSendEmail, {
         emails,
      });
      yield put(emailBuyerResolved(res));
   } catch (err) {
      yield put(emailBuyerRejected(err));
   }
}

export default function* () {
   // Request's
   yield takeEvery("emailBuyer/emailBuyerRequest", function* ({ payload }: AnyAction) {
      yield put({
         type: payload.type,
         ...payload,
      });
   });
   // Actions
   yield takeLatest(reqType.SEND_REQUEST, sendEmail);
}
