import React, { useEffect, useMemo } from "react";
// Styles
import "./styles/main.scss";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { bannerRequest } from "@features/Banner/store/slice";
// Utils
import { bannerRequestType } from "@features/Banner/utils/constants";
import { status } from "@utils/constants";
// Types
import { RootState } from "@redux/rootReducer";
// Icons
import CloseIcon from "@icons/CloseIcon.svg";
// Hooks
import { useLocalStorage } from "@hooks/useLocalStorage";

interface IProps {
   onChange?: (isActive?: boolean) => void;
}

const Banner = ({ onChange }: IProps) => {
   const [closedBanner, setClosedBanner] = useLocalStorage("closedBanner", "");
   // Redux methods
   const dispatch = useDispatch();
   const { status: bannerStatus, item } = useSelector((state: RootState) => state.banner);

   const isActive = useMemo<boolean>(() => {
      const condition =
         bannerStatus === status.RESOLVED && item?.id && closedBanner !== item?.id;
      onChange?.(condition || false);
      return condition as boolean;
   }, [bannerStatus, JSON.stringify(item), closedBanner]);

   const handleClose = () => {
      setClosedBanner(item?.id);
   };

   useEffect(() => {
      dispatch(
         bannerRequest({
            type: bannerRequestType.GET_BANNER,
         })
      );
   }, []);

   return isActive ? (
      <div
         className={`
         banner
         banner--${typeof item?.type === "string" ? item?.type : ""}
     `}
      >
         <p className="banner__message p-14-med">{item?.content}</p>
         <CloseIcon className="banner__close" onClick={handleClose} />
      </div>
   ) : (
      <React.Fragment></React.Fragment>
   );
};

export default Banner;
