import { AxiosError, AxiosResponse } from "axios";
// axios
import { axios } from "@redux/store";
//  Types
import { TBanner, TError } from "../../slice/types";
import { CallEffect, call } from "redux-saga/effects";

export function* handleGetBanner(): Generator<
   CallEffect<AxiosResponse>,
   {
      item: TBanner;
      error?: TError;
   },
   never
> {
   try {
      const res: AxiosResponse<{ items: TBanner[] }> = yield call(() => {
         return axios({
            url: "/banners",
            method: "GET",
         });
      });

      if (res.data.items.length) {
         return {
            item: res.data.items[0],
         };
      } else {
         return {
            item: {
               id: "",
               type: null,
               content: "",
               createdAt: "",
            },
         };
      }
   } catch (err) {
      const error = err as AxiosError<TError>;
      throw {
         error: error.response?.data,
      };
   }
}
