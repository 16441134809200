import { createSlice } from "@reduxjs/toolkit";
// Types
import { IStates } from "./types";
// Utils
import { status } from "@utils/constants";

const catalog = createSlice({
   name: "catalog",
   initialState: {
      csvProducts: {
         status: status.IDLE,
         error: {
            type: "",
            message: "",
            data: {},
         },
      },
      branches: {
         items: [],
         status: status.IDLE,
         error: {
            type: "",
            message: "",
            data: {},
         },
      },
      accessibleBranches: {
         items: [],
         status: status.IDLE,
         error: {
            type: "",
            message: "",
            data: {},
         },
      },
      suppliers: {
         items: [],
         status: status.IDLE,
         error: {
            type: "",
            message: "",
            data: {},
         },
      },
      warehouses: {
         items: [],
         status: status.IDLE,
         error: {
            type: "",
            message: "",
            data: {},
         },
      },
      category: {
         items: [],
         status: status.IDLE,
         error: {
            type: "",
            message: "",
            data: {},
         },
      },
      products: {
         items: {
            parentCategory: {
               category: "",
               id: "",
            },
            products: [],
            perPage: 0,
            page: 1,
            total: 0,
         },
         status: status.IDLE,
         error: {
            type: "",
            message: "",
            data: {},
         },
      },
      sortBy: {
         items: undefined,
         status: status.IDLE,
         error: {
            type: "",
            message: "",
            data: {},
         },
      },
   } as IStates,
   reducers: {
      catalogRequest: (state, { payload }) => {
         if ("stateType" in payload) {
            const stateType: keyof IStates = payload.stateType;
            state[stateType] = {
               ...(state[stateType] as object),
               status: status.PENDING,
            };
         }
      },
      catalogResolved: (state, { payload }) => {
         if ("stateType" in payload) {
            const stateType: keyof IStates = payload.stateType;
            state[stateType] = {
               status: status.RESOLVED,
               items: payload.items,
            };
         }
      },
      catalogRejected: (state, { payload }) => {
         if ("stateType" in payload) {
            const stateType: keyof IStates = payload.stateType;
            state[stateType] = {
               ...(state[stateType] as object),
               ...("items" in payload ? { items: payload.items } : {}),
               status: status.REJECTED,
               error: payload.error,
            };
         }
      },
   },
});

export const { catalogRequest, catalogResolved, catalogRejected } = catalog.actions;
export default catalog.reducer;
