import { AxiosError, AxiosResponse } from "axios";
// Saga
import { CallEffect, ForkEffect, PutEffect, call, fork, put } from "redux-saga/effects";
// Utils
import { status } from "@utils/constants";
import { productType } from "@features/Product/utils/constants";
// Store
import { axios } from "@redux/store";
// Actions
import {
   productUpdateMatch,
   productUpdateStock,
   productUpdateProduct,
   productUpdateDocument,
   productUpdateAttributes,
   productUpdateCategories,
} from "../../slice/index";
// Types
import { TCategories, TError, TMatch, TStock } from "../../slice/types";
import { AnyAction } from "@reduxjs/toolkit";
import { TProductPayload } from "../request/types";
import { TMatchShape } from "./types";
// UUID
import uuid from "react-uuid";
// Utils
import { currencyFormat } from "@utils/currencyFormat";

function* handleGetProduct({
   pid,
}: TProductPayload): Generator<
   CallEffect<AxiosResponse> | PutEffect<AnyAction>,
   void,
   never
> {
   try {
      const result: AxiosResponse = yield call(() => {
         return axios({
            url: `/products/headers/${pid as string}`,
            method: "GET",
         });
      });

      yield put(
         productUpdateProduct({
            status: status.RESOLVED,
            data: result.data,
            error: {},
         })
      );
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      yield put(
         productUpdateProduct({
            status: status.REJECTED,
            error: error.response?.data.error,
         })
      );
   }
}

function* handleGetProductCategories({
   pid,
}: TProductPayload): Generator<
   CallEffect<AxiosResponse> | PutEffect<AnyAction>,
   void,
   never
> {
   try {
      const result: AxiosResponse<TCategories> = yield call(() => {
         return axios({
            url: `/products/categories/${pid as string}`,
            method: "GET",
         });
      });

      yield put(
         productUpdateCategories({
            data: {
               branch: result.data?.branch,
               categories: result.data?.categories,
            },
            status: status.RESOLVED,
         })
      );
   } catch (err) {
      yield put(
         productUpdateCategories({
            data: {
               branch: {},
               categories: [],
            },
            status: status.REJECTED,
            error: err,
         })
      );
   }
}

function* handleGetProductMatch({
   pid,
   currency,
}: TProductPayload): Generator<
   CallEffect<AxiosResponse> | PutEffect<AnyAction>,
   void,
   never
> {
   try {
      const result: AxiosResponse<{
         items: TMatchShape[];
      }> = yield call(() => {
         return axios({
            url: `/products/match/${pid as string}/${currency?.currency || "USD"}`,
            method: "GET",
         });
      });

      // Update table shape
      const updatedShape = result?.data?.items.reduce((a: unknown[], c: TMatchShape) => {
         const shape: TMatch = {
            id: c?.id,
            branch: c?.branch?.name,
            branchPartNumber: c?.partNumber,
            supplier: c?.buyingOptions[0].supplier?.name,
            supplierPartNumber: c?.buyingOptions[0].supplier?.partNumber,
            price: currencyFormat(
               c?.buyingOptions[0].sell?.price as number,
               currency?.locale as string,
               currency?.currency as string
            ),
            isInList: c?.inList,
            pid: c?.id,
         };
         // If buyingOptions array only have 1 object inside we will push it directly to accumulator
         if (c.buyingOptions.length <= 1) {
            a.push(shape);
         } else {
            /**
             * Else we will iterate the buying options then
             * push new instance of object with each sell and supplier from buying options
             */
            c?.buyingOptions?.forEach((match) => {
               a.push({
                  ...shape,
                  id: `${c.id}${JSON.stringify(match)}`,
                  pid: match?.productId,
                  branch: match?.branch?.name,
                  branchPartNumber: match?.branch?.partNumber,
                  supplier: match?.supplier?.name,
                  supplierPartNumber: match?.supplier?.partNumber,
                  price: currencyFormat(
                     match.sell?.price as number,
                     currency?.locale as string,
                     currency?.currency as string
                  ),
               });
            });
         }
         return a;
      }, []);

      yield put(
         productUpdateMatch({
            status: status.RESOLVED,
            data: updatedShape,
            error: "",
         })
      );
   } catch (err) {
      yield put(
         productUpdateMatch({
            status: status.REJECTED,
            data: [],
            error: err,
         })
      );
   }
}

function* handleGetProductAttributes({
   pid,
}: TProductPayload): Generator<
   CallEffect<AxiosResponse> | PutEffect<AnyAction>,
   void,
   never
> {
   try {
      const result: AxiosResponse = yield call(() => {
         return axios({
            url: `/products/attributes/${pid as string}`,
            method: "GET",
         });
      });

      yield put(
         productUpdateAttributes({
            status: status.RESOLVED,
            data: result.data["items"],
            error: "",
         })
      );
   } catch (err) {
      yield put(
         productUpdateAttributes({
            status: status.REJECTED,
            error: err,
         })
      );
   }
}

function* handleGetProductDocument({
   pid,
}: TProductPayload): Generator<
   CallEffect<AxiosResponse> | PutEffect<AnyAction>,
   void,
   never
> {
   try {
      const result: AxiosResponse = yield call(() => {
         return axios({
            url: `/products/documents/${pid as string}`,
            method: "GET",
         });
      });
      yield put(
         productUpdateDocument({
            status: status.RESOLVED,
            data: result?.data?.items,
            error: "",
         })
      );
   } catch (err) {
      yield put(
         productUpdateDocument({
            status: status.REJECTED,
            error: err,
         })
      );
   }
}

function* handleGetProductStock({ pid }: TProductPayload): Generator<
   | CallEffect<
        AxiosResponse<{
           items: TStock[];
        }>
     >
   | PutEffect<AnyAction>,
   void,
   never
> {
   try {
      const result: AxiosResponse<{
         items: TStock[];
      }> = yield call(() => {
         return axios({
            url: `/products/stock/${pid as string}`,
            method: "GET",
         });
      });

      const items = result?.data?.items?.map((item) => {
         return {
            id: `${JSON.stringify(item) + uuid()}`,
            address: item.address,
            replenishment: item.replenishment,
            quantity: item.quantity,
         };
      });

      yield put(
         productUpdateStock({
            status: status.RESOLVED,
            data: items,
            error: "",
         })
      );
   } catch (err) {
      yield put(
         productUpdateStock({
            status: status.REJECTED,
            error: err,
         })
      );
   }
}

export function* handleFetchProduct(
   payload: TProductPayload
): Generator<ForkEffect, void, never> {
   const handlers = {
      [productType.PRODUCT]: handleGetProduct,
      [productType.STOCK]: handleGetProductStock,
      [productType.DOCUMENT]: handleGetProductDocument,
      [productType.ATTRIBUTES]: handleGetProductAttributes,
      [productType.CATEGORIES]: handleGetProductCategories,
      [productType.MATCH]: handleGetProductMatch,
   };

   type TProductType = Exclude<keyof typeof productType, "ALL">;
   if (payload.requests === "ALL") {
      const keys = Object.keys(handlers);
      for (let i = 0; i < keys.length; i++) {
         yield fork(handlers?.[keys[i] as TProductType], payload);
      }
   } else {
      const requests = payload.requests as TProductType[];
      for (let i = 0; i < requests.length; i++) {
         yield fork(handlers?.[requests[i]], payload);
      }
   }
}
