import { createSlice } from "@reduxjs/toolkit";
// Utils
import { status } from "@utils/constants";
// Types
import { IState } from "./types";

const banner = createSlice({
   name: "banner",
   initialState: {
      item: {
         id: "",
         type: null,
         content: "",
         createdAt: "",
      },
      status: status.IDLE,
      error: {},
   } as IState,
   reducers: {
      bannerRequest: (state, {}) => {
         state.status = status.PENDING;
      },
      bannerResolved: (state, { payload }) => {
         state.status = status.RESOLVED;
         state.item = payload.item;
         state.error = {
            type: "",
            message: "",
            data: {},
         };
      },
      bannerRejected: (state, { payload }) => {
         state.status = status.REJECTED;
         state.item = {
            id: "",
            type: null,
            content: "",
            createdAt: "",
         };
         state.error = payload.error;
      },
   },
});

export const { bannerRequest, bannerResolved, bannerRejected } = banner.actions;
export default banner.reducer;
