import { axios, store } from "@redux/store";
// Utils
import {
   productListStatusCode as code,
   productListRequestType as reqType,
   productListCacheKey as cacheKey,
} from "../../../utils/constants";
import { status } from "@utils/constants";
// Types
import { TError, TProduct } from "../../slice/types";
import { RootState } from "@redux/rootReducer";
import { TProductListPayload } from "../request/types";
import { AxiosError, AxiosResponse } from "axios";
// Saga
import { CallEffect, PutEffect, call, put } from "redux-saga/effects";
// Types
import { TGetProductListAxiosResponse } from "./types";
import { AnyAction } from "@reduxjs/toolkit";
import { TProductListStatusCodeType } from "../../../utils/constants";
import { productUpdateProduct } from "@features/Product/store/slice";
// Actions
import { productListRequest } from "../../slice";

export function* handleGetProductList({
   page,
   prevItems,
}: TProductListPayload): Generator<
   CallEffect<AxiosResponse>,
   {
      error?: TError;
      code: TProductListStatusCodeType;
      products: TProduct[];
      total?: number;
      perPage?: number;
      page?: number;
   },
   never
> {
   try {
      const result: AxiosResponse<TGetProductListAxiosResponse> = yield call(() => {
         return axios({
            url: `/lists/products?page=${page}&perPage=5`,
            method: "GET",
            cacheKey: `${cacheKey.GET_PRODUCT_LIST}-${page}`,
            shouldExpire: false,
         });
      });

      const updatedProducts = prevItems
         ?.concat(prevItems.length >= result.data.total ? [] : result?.data?.items)
         // Here we are filtering out duplicate products
         .filter(
            (e, i, s) => s.findIndex((f) => f.listItemId === e.listItemId) === i
         ) as TProduct[];

      return {
         products: updatedProducts,
         total: result.data?.total,
         perPage: result.data?.perPage,
         page: result.data?.page,
         code: code.GET_LIST_PRODUCT_SUCCESS,
         ...(!result?.data?.items?.length &&
            !prevItems?.length && {
               error: { message: "Your list is empty" },
            }),
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      const errorData = error.response?.data?.error;
      throw {
         error: {
            ...errorData,
            message:
               errorData?.message === "Active list not found"
                  ? "Your list is empty"
                  : // If no error message then we will return the error type
                    errorData?.message || errorData?.type,
         },
         code: code.GET_LIST_PRODUCT_FAILURE,
      };
   }
}

export function* handlePushProductIntoList({
   pid,
   total,
   pushedProduct,
}: TProductListPayload): Generator<
   CallEffect<AxiosResponse> | PutEffect<AnyAction>,
   {
      code: TProductListStatusCodeType;
      error?: TError;
      total?: number;
   },
   never
> {
   try {
      yield call(() => {
         return axios({
            url: `/lists/products/${pid as number}`,
            method: "POST",
            removeCacheKey: cacheKey.GET_PRODUCT_LIST,
         });
      });

      // We will update the pushed product "isList" to true
      yield put(
         productUpdateProduct({
            status: status.RESOLVED,
            data: {
               ...pushedProduct,
               inList: true,
            },
            error: "",
         })
      );

      const productListState = JSON.parse(JSON.stringify(store.getState().productList));
      /**
       * We will check if the total and length of the product are equal so that we can
       * know that the user scrolled to the very bottom
       */
      if (productListState?.products?.length === productListState?.total) {
         const page = () => {
            // Then we will check the qoutient of the total and the perPage
            const qoutientOfTotalAndPerPage = productListState?.total / 5;
            const isInteger = Number?.isInteger(qoutientOfTotalAndPerPage);
            return !isInteger
               ? Math.ceil(qoutientOfTotalAndPerPage)
               : qoutientOfTotalAndPerPage + 1;
         };
         // Then we will dispatch the getList saga so that the product list will update
         yield put(
            productListRequest({
               type: reqType.GET_LIST_PRODUCTS,
               code: "",
               page: page(),
               prevItems: productListState.products,
            })
         );
      }

      return {
         code: code.PUSH_PRODUCT_INTO_LIST_SUCCESS,
         total: ((total as number) += 1),
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;

      throw {
         error: error.response?.data.error,
         code: code.PUSH_PRODUCT_INTO_LIST_FAILURE,
         products: [],
      };
   }
}

export function* handleRemoveProductFromList({ pid }: TProductListPayload): Generator<
   CallEffect<AxiosResponse> | PutEffect,
   {
      error?: TError;
      code: TProductListStatusCodeType;
      products: TProduct[];
      total?: number;
   },
   never
> {
   try {
      yield call(() => {
         return axios({
            url: `/lists/products/${pid as number}`,
            method: "DELETE",
            removeCacheKey: cacheKey.GET_PRODUCT_LIST,
         });
      });

      const productListState = JSON.parse(
         JSON.stringify(store.getState().productList)
      ) as RootState["productList"];
      return {
         total: productListState.total - 1,
         code: code.REMOVE_PRODUCT_FROM_LIST_SUCCESS,
         products: productListState.products?.filter(
            (product) => product?.id !== pid?.toString()
         ),
         ...(productListState.total === 1
            ? {
                 error: {
                    message: "Your list is empty",
                 },
              }
            : {}),
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;

      throw {
         error: error.response?.data.error,
         total: 0,
         code: code.REMOVE_PRODUCT_FROM_LIST_FAILURE,
      };
   }
}

export function* handleDeleteList(): Generator<
   CallEffect<AxiosResponse>,
   {
      code: TProductListStatusCodeType;
      products?: TProduct[];
      error?: TError;
      total?: number;
   },
   never
> {
   try {
      yield call(() => {
         return axios({
            url: "/lists",
            method: "DELETE",
            removeCacheKey: cacheKey.GET_PRODUCT_LIST,
         });
      });
      return {
         code: code.DELETE_LIST_PRODUCT_SUCCESS,
         products: [],
         total: 0,
         error: {
            message: "Your list is empty",
         },
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         error: error.response?.data.error,
         code: code.DELETE_LIST_PRODUCT_FAILURE,
      };
   }
}

export function* handleSaveList({ listName }: TProductListPayload): Generator<
   CallEffect<AxiosResponse>,
   {
      code: TProductListStatusCodeType;
      error: TError;
      total?: number;
   },
   never
> {
   try {
      yield call(() => {
         return axios({
            url: "/lists/disable",
            method: "PATCH",
            payload: {
               saveListAs: listName,
            },
            removeCacheKey: cacheKey.GET_PRODUCT_LIST,
         });
      });
      return {
         code: code.SAVE_PRODUCT_LIST_SUCCESS,
         total: 0,
         error: {
            message: "Your list is empty",
         },
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         code: code.SAVE_PRODUCT_LIST_FAILURE,
         error: error.response?.data.error,
      };
   }
}
