import { all } from "redux-saga/effects";
// Sagas
import auth from "@features/Auth/store/saga/request";
import product from "@features/Product/store/saga/request";
import feedback from "@features/Feedback/store/saga/request";
import productList from "@features/ProductList/store/saga/request";
import accountManagement from "@features/AccountManagement/store/saga/request";
import searchResult from "@features/Search/store/saga/request";
import matches from "@features/Matches/store/saga/request";
import catalog from "@features/Catalog/store/saga/request";
import emailBuyer from "@features/EmailBuyer/store/saga/request";
import banner from "@features/Banner/store/saga/request";

function* rootSaga() {
   yield all([
      auth(),
      searchResult(),
      product(),
      feedback(),
      productList(),
      accountManagement(),
      catalog(),
      emailBuyer(),
      matches(),
      banner(),
   ]);
}

export default rootSaga;
