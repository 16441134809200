import { axios } from "@redux/store";
// Types
import { TFilterPrice, TFilterShape, TSearchProduct } from "../../slice/types";
import { TSearchResultPayload } from "../request/types";
import { TSearchFilterType, TSearchState } from "@features/Search/utils/types";
import { default as axiosOrig, AxiosResponse } from "axios";
// Saga
import { CallEffect, PutEffect, call } from "redux-saga/effects";
// Types
import {
   TFilterBranchAxiosResponse,
   TFilterPriceAxiosResponse,
   TSearchProductAxiosResponse,
   TFilterSuppliersAxiosResponse,
   TFilterWarehousesAxiosResponse,
} from "./types";
// Utils
import { SEARCH_STATE_TYPE, searchFilterType } from "@features/Search/utils/constants";
import { currencyFormat } from "@utils/currencyFormat";

export function* handleGetSearchResult({
   search,
   page,
   perPage,
   filters,
   prevItems,
   currency,
}: TSearchResultPayload): Generator<
   CallEffect<AxiosResponse> | PutEffect,
   {
      items: TSearchProduct[];
      total: number;
      stateType: TSearchState;
      error?: unknown;
   },
   never
> {
   const cancelToken = axiosOrig.CancelToken.source();
   try {
      /**
       * As the search endpoint gives error when one of these (suppliers, warehouses, branch)
       * arrays is empty we will conditionally add them in the filters.
       */
      const conditionallyAddedMember = (arr: unknown[] = [], key: string) => {
         return {
            ...(arr && arr.join("").toString() && arr.length ? { [key]: arr } : {}),
         };
      };

      // Temporarily removed the fields, sort and filters
      const result: AxiosResponse<TSearchProductAxiosResponse> = yield call(() => {
         return axios({
            url: `/search/products?currency=${currency?.currency || "USD"}`,
            method: "POST",
            cancelToken: cancelToken.token,
            retry: 2,
            payload: {
               search: {
                  query: search,
               },
               filters: {
                  // priceMin: filters?.priceMin,
                  // priceMax: filters?.priceMax,
                  ...conditionallyAddedMember(filters?.branches, "branchIds"),
                  ...conditionallyAddedMember(filters?.suppliers, "supplierIds"),
                  ...conditionallyAddedMember(filters?.warehouses, "warehouseIds"),
                  stocks: filters?.stocks,
                  sells: filters?.sells,
               },
               // sort: {
               //    field: "price",
               //    direction: filters?.sortPrice || "desc",
               // },
               pagination: {
                  page,
                  perPage,
               },
            },
         });
      });

      const branches: AxiosResponse<TFilterBranchAxiosResponse> = yield call(() => {
         return axios({
            url: "/branches",
            cacheKey: "branches",
            shouldExpire: false,
            method: "GET",
         });
      });

      // We will update the items and append the branchName
      const updatedShapeItems =
         result?.data?.items?.map((item) => {
            // Find manufacturer name
            const branchName =
               branches.data?.items?.find((branch) => branch?.id === item?.branchId)
                  ?.name || "";
            // Then append it to the object
            item = {
               ...item,
               price: item?.price
                  ? currencyFormat(
                       Number(item?.price),
                       currency?.locale as string,
                       currency?.currency as string
                    )
                  : null,
               branchName,
            };

            return item;
         }) || [];

      // Then we will return the updated shape of the search result item
      return {
         items:
            page === 1
               ? updatedShapeItems
               : (prevItems?.concat(updatedShapeItems) as TSearchProduct[]),
         total: result.data.total,
         stateType: SEARCH_STATE_TYPE.SEARCH,
      };
   } catch (err) {
      throw {
         stateType: SEARCH_STATE_TYPE.SEARCH,
         error: err,
      };
   } finally {
      cancelToken.cancel();
   }
}

export function* handleGetFilterBranches(): Generator<
   CallEffect<AxiosResponse<TFilterBranchAxiosResponse>>,
   {
      filter: TSearchFilterType;
      error?: unknown;
      items?: TFilterShape[];
      stateType: TSearchState;
   },
   never
> {
   try {
      const res: AxiosResponse<TFilterBranchAxiosResponse> = yield call(() => {
         return axios({
            url: "/branches",
            cacheKey: "branches",
            shouldExpire: false,
            method: "GET",
         });
      });

      return {
         filter: searchFilterType.BRANCH,
         stateType: SEARCH_STATE_TYPE.FILTER,
         items: res.data["items"],
      };
   } catch (err) {
      throw {
         filter: searchFilterType.BRANCH,
         error: err,
         stateType: SEARCH_STATE_TYPE.FILTER,
      };
   }
}

export function* handleGetFilterSupplier({
   search,
   warehouses,
   branches,
   sells,
   stocks,
}: TSearchResultPayload): Generator<
   CallEffect<AxiosResponse<TFilterSuppliersAxiosResponse>>,
   {
      stateType: TSearchState;
      filter: TSearchFilterType;
      error?: unknown;
      items?: Omit<TFilterShape, "language" | "country">[];
   },
   never
> {
   try {
      const res: AxiosResponse<TFilterSuppliersAxiosResponse> = yield call(() => {
         return axios({
            url: "/search/suppliers",
            method: "POST",
            payload: {
               search: {
                  query: search,
               },
               filters: {
                  warehouseIds: warehouses,
                  branchIds: branches?.join("") ? branches : undefined,
                  sells,
                  stocks,
               },
            },
         });
      });

      return {
         filter: searchFilterType.SUPPLIERS,
         stateType: SEARCH_STATE_TYPE.FILTER,
         items: res.data?.["items"],
      };
   } catch (err) {
      throw {
         filter: searchFilterType.SUPPLIERS,
         error: err,
         stateType: SEARCH_STATE_TYPE.FILTER,
      };
   }
}

export function* handleGetFilterWarehouses({
   search,
   suppliers,
   branches,
   sells,
   stocks,
}: TSearchResultPayload): Generator<
   CallEffect<AxiosResponse<TFilterSuppliersAxiosResponse>>,
   {
      stateType: TSearchState;
      filter: TSearchFilterType;
      error?: unknown;
      items?: Omit<TFilterShape, "language" | "country">[];
   },
   never
> {
   try {
      const res: AxiosResponse<TFilterWarehousesAxiosResponse> = yield call(() => {
         return axios({
            url: "/search/warehouses",
            method: "POST",
            payload: {
               search: {
                  query: search,
               },
               filters: {
                  supplierIds: suppliers,
                  branchIds: branches?.join("") ? branches : undefined,
                  sells,
                  stocks,
               },
            },
         });
      });

      const updatedShape = res?.data?.items?.map((warehouse) => {
         return {
            id: warehouse?.id,
            name: warehouse?.address,
         };
      });

      return {
         filter: searchFilterType.WAREHOUSES,
         stateType: SEARCH_STATE_TYPE.FILTER,
         items: updatedShape,
      };
   } catch (err) {
      throw {
         filter: searchFilterType.WAREHOUSES,
         error: err,
         stateType: SEARCH_STATE_TYPE.FILTER,
      };
   }
}

export function* handleGetFilterPrice(): Generator<
   CallEffect<AxiosResponse<TFilterPriceAxiosResponse>>,
   {
      stateType: TSearchState;
      filter: TSearchFilterType;
      error?: unknown;
      items?: TFilterPrice;
   },
   never
> {
   try {
      const res: AxiosResponse<TFilterPriceAxiosResponse> = yield call(() => {
         return axios({
            url: "/products/prices",
            method: "GET",
         });
      });

      return {
         items: res.data,
         filter: searchFilterType.PRICE,
         stateType: SEARCH_STATE_TYPE.FILTER,
         error: res.data,
      };
   } catch (err) {
      throw {
         filter: searchFilterType.PRICE,
         error: err,
         stateType: SEARCH_STATE_TYPE.FILTER,
      };
   }
}
