export const currencyFormat = (
   number: number,
   locale: string,
   currency: string
): string => {
   const formatter = new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
   });
   return formatter.format(number);
};
