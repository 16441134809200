import { createSlice } from "@reduxjs/toolkit";
// Utils
import { status } from "@utils/constants";
// Types
import { IStates } from "./types";
import { productType } from "@features/Product/utils/constants";

const product = createSlice({
   name: "product",
   initialState: {
      product: {
         data: {
            branch: {
               id: "",
               name: "",
            },
            supplier: {
               id: "",
               name: "",
            },
            sell: {
               id: "",
               name: "",
            },
            description: "",
            id: "",
            imageUrl: "",
            inList: false,
            name: "",
            partNumber: "",
         },
         status: status.IDLE,
         error: {
            message: "",
            type: "",
         },
      },
      categories: {
         data: {
            branch: {
               id: "",
               name: "",
            },
            categories: [],
         },
         status: status.IDLE,
         error: {
            message: "",
            type: "",
         },
      },
      match: {
         data: [],
         status: status.IDLE,
         error: {
            message: "",
            type: "",
         },
      },
      attributes: {
         data: [],
         status: status.IDLE,
         error: {
            message: "",
            type: "",
         },
      },
      document: {
         data: [],
         status: status.IDLE,
         error: {
            message: "",
            type: "",
         },
      },
      stock: {
         data: [],
         status: status.IDLE,
         error: {
            message: "",
            type: "",
         },
      },
   } as IStates,
   reducers: {
      productRequest: (state, { payload }) => {
         // Check if payload request is "All"
         const isAll = payload?.requests === "ALL";
         // Then we will extract the keys from productType constants but we will exclude the "ALL"
         type TProductType = Exclude<keyof typeof productType, "ALL">;
         /**
          * Here we will check if the requests is "ALL" we will pass the product type keys as array else
          * we will pass the payload request itself
          */
         (
            (isAll ? Object.keys(productType) : payload.requests) as TProductType[]
         ).forEach((key: TProductType) => {
            // As the productType is all uppercase we will convert it to lowercase because state names are lowercase
            const lowerCaseState = key.toLocaleLowerCase() as Lowercase<TProductType>;
            // Then we will update the states
            state[lowerCaseState] = {
               ...state[lowerCaseState],
               data: payload?.data || state[lowerCaseState]?.data,
               status: status.PENDING,
            };
         });
      },
      productUpdateProduct: (state, { payload }) => {
         state.product = {
            ...state.product,
            ...payload,
         };
      },
      productUpdateMatch: (state, { payload }) => {
         state.match = {
            ...state.match,
            ...payload,
         };
      },
      productUpdateCategories: (state, { payload }) => {
         state.categories = {
            ...state.categories,
            ...payload,
         };
      },
      productUpdateAttributes: (state, { payload }) => {
         state.attributes = {
            ...state.attributes,
            ...payload,
         };
      },
      productUpdateDocument: (state, { payload }) => {
         state.document = {
            ...state.document,
            ...payload,
         };
      },
      productUpdateStock: (state, { payload }) => {
         state.stock = {
            ...state.stock,
            ...payload,
         };
      },
   },
});

export const {
   productRequest,
   productUpdateProduct,
   productUpdateMatch,
   productUpdateAttributes,
   productUpdateDocument,
   productUpdateStock,
   productUpdateCategories,
} = product.actions;
export default product.reducer;
