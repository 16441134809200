import { createSlice } from "@reduxjs/toolkit";
// Utils
import { status } from "@utils/constants";
// Types
import { IStates } from "./types";

const matches = createSlice({
   name: "matches",
   initialState: {
      items: [],
      page: 0,
      perPage: 0,
      total: 0,
      status: status.IDLE,
      error: {},
   } as IStates,
   reducers: {
      matchesRequest: (state, {}) => {
         state.status = status.PENDING;
      },
      matchesResolved: (state, { payload }) => {
         state.status = status.RESOLVED;
         state.items = payload.items;
         state.page = payload.page;
         state.perPage = payload.perPage;
         state.total = payload.total;
      },
      matchesRejected: (state, { payload }) => {
         state.status = status.REJECTED;
         state.error = payload.error;
      },
   },
});

export const { matchesRequest, matchesResolved, matchesRejected } = matches.actions;
export default matches.reducer;
