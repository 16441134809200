import { put, call, takeLatest, SagaReturnType, takeEvery } from "redux-saga/effects";
// Handlers
import {
   handleGetFilterBranches,
   handleGetFilterSupplier,
   handleGetFilterPrice,
   handleGetSearchResult,
   handleGetFilterWarehouses,
} from "../handlers/index";
// Utils
import { searchResultRequestType as reqType } from "@features/Search/utils/constants";
// Actions
import { searchResultResolved, searchResultRejected } from "../../slice/index";
// Types
import { AnyAction } from "@reduxjs/toolkit";
import { TSearchResultPayload } from "./types";

function* getSearchResult({
   search,
   page,
   perPage,
   filters,
   prevItems,
   currency,
}: TSearchResultPayload & AnyAction): unknown {
   try {
      const result: SagaReturnType<typeof handleGetSearchResult> = yield call(
         handleGetSearchResult,
         {
            search,
            page,
            filters,
            prevItems,
            currency,
            perPage,
         }
      );
      yield put(searchResultResolved(result));
   } catch (err) {
      yield put(searchResultRejected(err));
   }
}

function* getFilterBranch() {
   try {
      const res: SagaReturnType<typeof handleGetFilterBranches> = yield call(
         handleGetFilterBranches
      );
      yield put(searchResultResolved(res));
   } catch (err) {
      yield put(searchResultRejected(err));
   }
}

function* getFilterSupplier({
   search,
   warehouses,
   branches,
   sells,
   stocks,
}: TSearchResultPayload & AnyAction) {
   try {
      const res: SagaReturnType<typeof handleGetFilterSupplier> = yield call(
         handleGetFilterSupplier,
         { search, warehouses, branches, sells, stocks }
      );
      yield put(searchResultResolved(res));
   } catch (err) {
      yield put(searchResultRejected(err));
   }
}

function* getFilterWarehouses({
   search,
   suppliers,
   branches,
   sells,
   stocks,
}: TSearchResultPayload & AnyAction) {
   try {
      const res: SagaReturnType<typeof handleGetFilterWarehouses> = yield call(
         handleGetFilterWarehouses,
         { search, suppliers, branches, sells, stocks }
      );
      yield put(searchResultResolved(res));
   } catch (err) {
      yield put(searchResultRejected(err));
   }
}

function* getFilterPrice() {
   try {
      const res: SagaReturnType<typeof handleGetFilterPrice> = yield call(
         handleGetFilterPrice
      );
      yield put(searchResultResolved(res));
   } catch (err) {
      yield put(searchResultRejected(err));
   }
}

export default function* (): unknown {
   // Reqyest's
   yield takeEvery(
      "searchResult/searchResultRequest",
      function* ({ payload }: AnyAction) {
         yield put({
            type: payload.type,
            ...payload,
         });
      }
   );
   // Actions
   yield takeLatest(reqType.GET_SEARCH_RESULT, getSearchResult);
   yield takeLatest(reqType.GET_FILTER_BRANCH, getFilterBranch);
   yield takeLatest(reqType.GET_FILTER_SUPPLIERS, getFilterSupplier);
   yield takeLatest(reqType.GET_SEARCH_WAREHOUSES, getFilterWarehouses);
   yield takeLatest(reqType.GET_FILTER_PRICE, getFilterPrice);
}
