import { axios } from "@redux/store";
// Types
import { TPayload } from "../request/types";
import { TError, TMatchesShape } from "../../slice/types";
import { AxiosResponse } from "axios";
import { TMatch } from "@features/Product/store/slice/types";
// Utils
import { currencyFormat } from "@utils/currencyFormat";

// const t = [
//    {
//       clusterId: "10050",
//       items: [
//          {
//             clusterId: "10050",
//             productId: "096101bc-58c7-41b1-afc1-4ecc82d01c81",
//             branch: {
//                id: "54482953-1858-4def-9633-980a547373d1",
//                name: "United Kingdom",
//                partNumber: "2",
//             },
//             sell: {
//                id: "9ddd983b-a632-4f5f-a975-c16cebdcfe9f",
//                price: 203.52,
//             },
//             supplier: {
//                id: "0d9f4206-b55f-4cd1-9b09-be576ff8ff78",
//                name: "RUNPOTEC GmbH",
//                partNumber: "10050",
//             },
//          },
//          {
//             clusterId: "10050",
//             productId: "25cef37c-2fc7-4f90-a00d-2b075c1f9f8c",
//             branch: {
//                id: "6f9aac50-7834-4bc8-a0a8-a9d4130e49d5",
//                name: "New Jersey",
//                partNumber: "3",
//             },
//             sell: {
//                id: "d3227398-861d-4a5a-94d5-23aaaedfa65a",
//                price: 373,
//             },
//             supplier: {
//                id: "50672f52-56c0-4aa3-a37b-beb1102c8590",
//                name: "RUNPOTEC GMBH",
//                partNumber: "10050",
//             },
//          },
//          {
//             clusterId: "10050",
//             productId: "725e77e7-404c-4646-80fd-cf39229d2deb",
//             branch: {
//                id: "0535b922-aa8c-44ec-b64c-dc077df176d8",
//                name: "Portugal",
//                partNumber: "6",
//             },
//             sell: {
//                id: "362d5b3c-89cb-4504-a21f-5ddb2d8fd6ed",
//                price: 79.8,
//             },
//             supplier: {
//                id: "a7ae9e5c-2c6f-410f-b98c-9450e21e64b0",
//                name: "JETTING AB",
//                partNumber: "10050",
//             },
//          },
//          {
//             clusterId: "10050",
//             productId: "95b69ffa-1739-4185-bb98-0a40bd00b635",
//             branch: {
//                id: "54482953-1858-4def-9633-980a547373d1",
//                name: "United Kingdom",
//                partNumber: "8",
//             },
//             sell: {
//                id: "087f8e63-a3f0-4274-8d80-2bf3c7f47fcf",
//                price: 79.8,
//             },
//             supplier: {
//                id: "11a2e278-81c4-488a-8b4a-c5e3ed26e4f4",
//                name: "JETTING AB",
//                partNumber: "10050",
//             },
//          },
//       ],
//    },
//    {
//       clusterId: "10051",
//       items: [
//          {
//             clusterId: "10051",
//             productId: "6754d6e1-3a92-47df-9311-30c3a6e57e36",
//             branch: {
//                id: "0535b922-aa8c-44ec-b64c-dc077df176d8",
//                name: "Portugal",
//                partNumber: "9",
//             },
//             sell: {
//                id: "28036b0b-e914-4f3a-978e-3058d25c6c77",
//                price: 79.8,
//             },
//             supplier: {
//                id: "a7ae9e5c-2c6f-410f-b98c-9450e21e64b0",
//                name: "JETTING AB",
//                partNumber: "10051",
//             },
//          },
//          {
//             clusterId: "10051",
//             productId: "71b490df-1f5f-4377-ab28-2613bbd22c70",
//             branch: {
//                id: "54482953-1858-4def-9633-980a547373d1",
//                name: "United Kingdom",
//                partNumber: "10",
//             },
//             sell: {
//                id: "58ba1c04-67a0-4a03-b486-74303aa9fd64",
//                price: 79.8,
//             },
//             supplier: {
//                id: "11a2e278-81c4-488a-8b4a-c5e3ed26e4f4",
//                name: "JETTING AB",
//                partNumber: "10051",
//             },
//          },
//       ],
//    },
// ];

export const handleGetMatches = async ({
   branchId,
   page,
   perPage,
   currency,
}: TPayload): Promise<{
   items?: {
      id: string;
      matches: Omit<TMatch, "isInList">[];
   }[];
   perPage?: number;
   page?: number;
   total?: number;
   error?: TError;
}> => {
   try {
      const res: AxiosResponse<{
         items: TMatchesShape[];
         page: number;
         perPage: number;
         total: number;
      }> = await axios({
         url: `/products/matches/${branchId}/${currency?.currency || "USD"}?page=${
            page || 1
         }&perPage=${perPage || 5}`,
         method: "GET",
      });

      const updatedShape: {
         id: string;
         matches: Omit<TMatch, "isInList">[];
      }[] = res.data.items.map((item) => {
         return {
            id: item.clusterId,
            matches: item.items.reduce((a: Omit<TMatch, "isInList">[], c, index) => {
               a.push({
                  id: `${c?.clusterId}${index}`,
                  branch: c?.branch.name,
                  branchPartNumber: c?.branch.partNumber,
                  supplier: c?.supplier !== null ? c?.supplier.name : "-",
                  supplierPartNumber: c?.supplier !== null ? c?.supplier.partNumber : "-",
                  price:
                     c?.sell !== null
                        ? currencyFormat(
                             c?.sell.price,
                             currency?.locale as string,
                             currency?.currency as string
                          )
                        : "-",
                  pid: c?.productId,
               });
               return a;
            }, []),
         };
      });

      return {
         items: updatedShape,
         perPage: res.data.perPage,
         page: res.data.page,
         total: res.data.total,
      };
   } catch (err) {
      throw {
         error: err,
      };
   }
};
