import { createSlice } from "@reduxjs/toolkit";
// Utils
import { status } from "@utils/constants";
import { SEARCH_STATE_TYPE } from "@features/Search/utils/constants";
// Types
import { IStates, TSearchFilter } from "./types";

const searchResult = createSlice({
   name: "searchResult",
   initialState: {
      currency: {
         currency: "USD",
         locale: "en-US",
         symbol: "$",
      },
      search: {
         items: [],
         error: {},
         status: status.IDLE,
         total: 0,
      },
      filters: {
         branch: {
            items: [],
            error: {},
            status: status.IDLE,
         },
         suppliers: {
            items: [],
            error: {},
            status: status.IDLE,
         },
         warehouses: {
            items: [],
            error: {},
            status: status.IDLE,
         },
         price: {
            items: {
               min: 0,
               max: 100,
            },
            status: status.IDLE,
            error: {},
         },
      },
   } as IStates,
   reducers: {
      searchUpdateCurrency: (state, { payload }) => {
         state.currency = payload.currency;
      },
      searchResultRequest: (state, { payload }) => {
         if (payload.stateType === SEARCH_STATE_TYPE.SEARCH) {
            state.search = {
               ...state.search,
               ...("total" in payload ? { total: payload.toal } : {}),
               status: status.PENDING,
               error: {},
            };
         }

         if (payload.stateType === SEARCH_STATE_TYPE.FILTER) {
            if ("filter" in payload) {
               const filterName: keyof TSearchFilter = payload.filter;
               state.filters[filterName] = {
                  items: payload.items,
                  status: status.PENDING,
                  error: {},
               };
            }
         }
      },
      searchResultResolved: (state, { payload }) => {
         if (payload.stateType === SEARCH_STATE_TYPE.SEARCH) {
            state.search = {
               items: payload.items,
               status: status.RESOLVED,
               error: {},
               total: payload.total,
            };
         }

         if (payload.stateType === SEARCH_STATE_TYPE.FILTER) {
            if ("filter" in payload) {
               const filterName: keyof TSearchFilter = payload.filter;
               state.filters[filterName] = {
                  items: payload.items,
                  status: status.RESOLVED,
                  error: {},
               };
            }
         }
      },
      searchResultRejected: (state, { payload }) => {
         if (payload.stateType === SEARCH_STATE_TYPE.SEARCH) {
            state.search = {
               ...state.search,
               error: payload.error,
               status: status.REJECTED,
            };
         }

         if (payload.stateType === SEARCH_STATE_TYPE.FILTER) {
            if ("filter" in payload) {
               const filterName: keyof TSearchFilter = payload.filter;
               state.filters[filterName] = {
                  items: payload.items,
                  status: status.REJECTED,
                  error: payload.error,
               };
            }
         }
      },
   },
});

export const {
   searchUpdateCurrency,
   searchResultRequest,
   searchResultResolved,
   searchResultRejected,
} = searchResult.actions;
export default searchResult.reducer;
