import { axios } from "@redux/store";
// Saga
import { CallEffect, call } from "@redux-saga/core/effects";
// Utils
import { currencyFormat } from "@utils/currencyFormat";
import { stateType } from "@features/Catalog/utils/constants";
// Types
import { TCatalogPayload } from "../request/types";
import { AxiosError, AxiosResponse } from "axios";
import {
   TGetCatalogBranchesAxiosResponse,
   TGetCatalogProductsAxiosResponse,
   TGetCatalogSuppliersAxiosResponse,
   TGetCatalogWarehousesAxiosResponse,
   TGetCategoriesAxiosResponse,
} from "./types";
import {
   TCatalogProduct,
   TCategoryProduct,
   TCategoryType,
   TError,
} from "../../slice/types";
import { TStateType } from "@features/Catalog/utils/constants";
import { TFilterShape } from "@features/Search/store/slice/types";
// Axios
import { default as axiosOrig } from "axios";
// Download csv
import { mkConfig, generateCsv, download } from "export-to-csv";

export function* handleGetCategory({ level, filters }: TCatalogPayload): Generator<
   CallEffect<AxiosResponse | AxiosResponse[]>,
   {
      items?: TCategoryType;
      stateType?: TStateType;
      error?: TError;
   },
   never
> {
   try {
      const responses: Promise<AxiosResponse>[] = [];

      for (let i = 0; i < (level || 1); i++) {
         responses.push(
            axios({
               url: "/catalog/categories",
               method: "POST",
               payload: {
                  level,
                  filters: {
                     ...filters,
                     ...(filters?.categoryId?.length
                        ? { categoryId: filters?.categoryId[i] }
                        : {}),
                  },
               },
            })
         );
      }

      const res: AxiosResponse<TGetCategoriesAxiosResponse>[] = yield call(async () => {
         return await Promise.all(responses);
      });

      return {
         items: res.map((e) => e.data) as unknown as TCategoryType,
         stateType: stateType.category,
      };
   } catch (err) {
      const error = err as AxiosError<TError>;
      throw {
         error: error?.response?.data,
         items: [],
      };
   }
}

export function* handleExportCsvProducts({
   filters,
   total,
   currency,
   sort,
}: TCatalogPayload): Generator<
   CallEffect<AxiosResponse[]>,
   {
      stateType: TStateType;
      error?: TError;
   },
   never
> {
   try {
      const response: Promise<AxiosResponse>[] = [];
      const perPage = 500;

      for (let i = 1; i <= Math.ceil((total as number) / perPage); i++) {
         response.push(
            axios({
               url: `/catalog/products?currency=${currency?.currency || "USD"}`,
               payload: {
                  filters: {
                     ...filters,
                     ...(filters?.categoryId
                        ? { categoryId: filters.categoryId.slice(-1)[0] }
                        : {}),
                  },
                  pagination: {
                     page: i,
                     perPage,
                  },
                  sort,
               },
               method: "POST",
            })
         );
      }

      const res: AxiosResponse<TGetCatalogProductsAxiosResponse>[] = yield call(
         async () => {
            return await Promise.all(response);
         }
      );

      const updatedResult = res
         ?.map((e) => {
            // We will extract the payload from the response so that we can sort it by page
            const payload = JSON.parse(e?.config?.data as string) as {
               filters: object;
               pagination: {
                  page: number;
                  perPage: number;
               };
            };
            // Finally return the item and the page so that we can sort it below
            return {
               data: e,
               page: payload?.pagination?.page,
            };
         })
         ?.sort((a, b) => a?.page - b?.page)
         // Then map the items so that only the data from axiosResponse will be returned
         ?.map((e) => e?.data?.data?.items)
         // As the result above is an array of arrays, we want to return only array of objects
         ?.reduce((a, c) => [...a, ...c], []);

      // We will update the items and append the branchName and remove price
      const updatedShapeProducts: Omit<
         TCategoryProduct,
         "price" | "id" | "branchId" | "branchName"
      >[] =
         updatedResult?.map((item) => {
            const replaceQoutes = (text: string) => {
               // eslint-disable-next-line quotes
               return text.replace(/"/g, String('""'));
            };

            return {
               partNumber: replaceQoutes(item?.partNumber),
               name: replaceQoutes(item?.name),
               description: replaceQoutes(item?.description),
               stockQuantity: item?.stockQuantity,
            };
         }) || [];

      /**
       * We will handle the donwloading of the csv here using this library
       * https://www.npmjs.com/package/export-to-csv -> to learn more follow the link
       */
      // First we will create the config
      const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: "catalogProducts" });
      // Converts your Array<Object> to a CsvOutput string based on the configs
      const csv = generateCsv(csvConfig)(updatedShapeProducts);
      // Then download the csv using the download function that is provided by the library
      download(csvConfig)(csv);
      // Finally we will return the stateType
      return {
         stateType: "csvProducts",
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         stateType: stateType.csvProducts,
         items: [],
         error: error.response?.data.error,
      };
   }
}

export function* handleGetCatalogProducts({
   page,
   prevItems,
   filters,
   currency,
   perPage,
   sort,
}: TCatalogPayload): Generator<
   CallEffect<AxiosResponse>,
   {
      items: TCatalogProduct;
      stateType: TStateType;
      error?: TError;
   },
   never
> {
   const cancelToken = axiosOrig.CancelToken.source();
   try {
      const res: AxiosResponse<TGetCatalogProductsAxiosResponse> = yield call(() => {
         return axios({
            url: `/catalog/products?currency=${currency?.currency || "USD"}`,
            payload: {
               filters: {
                  ...filters,
                  ...(filters?.categoryId
                     ? { categoryId: filters.categoryId.slice(-1)[0] }
                     : {}),
               },
               pagination: {
                  page,
                  perPage,
               },
               sort,
            },
            method: "POST",
            cancelToken: cancelToken.token,
         });
      });

      const branches: AxiosResponse<{
         items: TFilterShape[];
      }> = yield call(() => {
         return axios({
            url: "/branches",
            cacheKey: "branches",
            shouldExpire: false,
            method: "GET",
         });
      });

      // We will update the items and append the branchName
      const updatedShapeProducts =
         res.data?.items.map((item) => {
            const branchName = branches.data?.items?.find(
               (branch) => branch.id === item.branchId
            )?.name;

            item = {
               ...item,
               branchName,
               price: item?.price
                  ? currencyFormat(
                       Number(item?.price),
                       currency?.locale as string,
                       currency?.currency as string
                    )
                  : null,
            };
            return item;
         }) || [];

      return {
         stateType: stateType.products,
         items: {
            perPage: perPage as number,
            page: page as number,
            total: res.data?.total,
            products:
               page === 1
                  ? updatedShapeProducts
                  : (prevItems?.concat(updatedShapeProducts) as TCategoryProduct[]),
         },
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         stateType: stateType.products,
         items: [],
         error: error.response?.data.error,
      };
   } finally {
      cancelToken.cancel();
   }
}

export function* handleGetCatalogBranch(): Generator<
   CallEffect<AxiosResponse>,
   {
      items: TFilterShape[];
      stateType: TStateType;
      error?: TError;
   },
   never
> {
   try {
      const res: AxiosResponse<TGetCatalogBranchesAxiosResponse> = yield call(() => {
         return axios({
            url: "/branches",
            cacheKey: "branches",
            shouldExpire: false,
            method: "GET",
         });
      });

      return {
         stateType: stateType.branch,
         items: res.data.items,
      };
   } catch (err) {
      const error = err as AxiosError<TError>;
      throw {
         stateType: stateType.branch,
         error: error.response?.data,
      };
   }
}

export function* handleGetAccessibleBranches(): Generator<
   CallEffect<AxiosResponse>,
   {
      items: TFilterShape[];
      stateType: TStateType;
      error?: TError;
   },
   never
> {
   try {
      const res: AxiosResponse<TGetCatalogBranchesAxiosResponse> = yield call(() => {
         return axios({
            url: "/branches/accessible",
            cacheKey: "accessibleBranches",
            method: "GET",
         });
      });

      return {
         stateType: stateType.accessibleBranches,
         items: res.data.items,
      };
   } catch (err) {
      const error = err as AxiosError<TError>;
      throw {
         stateType: stateType.branch,
         error: error.response?.data,
      };
   }
}

export function* handleGetCatalogSuppliers({ filters }: TCatalogPayload): Generator<
   CallEffect<AxiosResponse>,
   {
      items: Omit<TFilterShape, "language" | "country">[];
      stateType: TStateType;
      error?: TError;
   },
   never
> {
   try {
      // Then do the rest
      const res: AxiosResponse<TGetCatalogSuppliersAxiosResponse> = yield call(() => {
         return axios({
            url: "/catalog/suppliers",
            method: "POST",
            payload: {
               search: {
                  query: "",
               },
               filters: {
                  warehouseIds: filters?.warehouseIds,
                  branchIds: [filters?.branchId],
                  sells: filters?.sells,
                  stocks: filters?.stocks,
                  ...(filters?.categoryId
                     ? { categoryId: filters.categoryId.slice(-1)[0] }
                     : {}),
               },
            },
         });
      });

      return {
         stateType: stateType.suppliers,
         items: res.data.items,
      };
   } catch (err) {
      const error = err as AxiosError<TError>;
      throw {
         stateType: stateType.suppliers,
         error: error.response?.data,
      };
   }
}

export function* handleGetCatalogWarehouses({ filters }: TCatalogPayload): Generator<
   CallEffect<AxiosResponse>,
   {
      items: Omit<TFilterShape, "language" | "country">[];
      stateType: TStateType;
      error?: TError;
   },
   never
> {
   try {
      // Then do the rest
      const res: AxiosResponse<TGetCatalogWarehousesAxiosResponse> = yield call(() => {
         return axios({
            url: "/catalog/warehouses",
            method: "POST",
            payload: {
               search: {
                  query: "",
               },
               filters: {
                  supplierIds: filters?.supplierIds,
                  branchIds: [filters?.branchId],
                  sells: filters?.sells,
                  stocks: filters?.stocks,
                  ...(filters?.categoryId
                     ? { categoryId: filters.categoryId.slice(-1)[0] }
                     : {}),
               },
            },
         });
      });

      // We will update the shape
      const updatedShape = res.data?.items?.map((e) => {
         return {
            id: e.id,
            name: e.address,
         };
      });

      return {
         stateType: stateType.warehouses,
         items: updatedShape,
      };
   } catch (err) {
      const error = err as AxiosError<TError>;
      throw {
         stateType: stateType.warehouses,
         error: error.response?.data,
      };
   }
}
