export const productCacheKey = {
   GET_PRODUCT: "GET_PRODUCT",
   GET_MATCH: "GET_MATCH",
   GET_DOCUMENT: "GET_DOCUMENT",
   GET_ATTR: "GET_ATTR",
   GET_STOCK: "GET_STOCK",
};

export const productType = {
   ALL: "ALL" as const,
   MATCH: "MATCH" as const,
   STOCK: "STOCK" as const,
   PRODUCT: "PRODUCT" as const,
   DOCUMENT: "DOCUMENT" as const,
   ATTRIBUTES: "ATTRIBUTES" as const,
   CATEGORIES: "CATEGORIES" as const,
};
