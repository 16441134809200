// Types
import { AllEffect, CallEffect, all, call } from "redux-saga/effects";
// Types
import { TEmailBuyerPayload } from "../request/types";
import { TError } from "../../slice/types";
// Axios
import { axios } from "@redux/store";
import { AxiosError } from "axios";

export function* handleSendEmail({ emails }: TEmailBuyerPayload): Generator<
   AllEffect<CallEffect>,
   {
      error?: TError;
      successMessage: string;
   },
   never
> {
   try {
      yield all(
         emails.map((email) => {
            return call(() => {
               return axios({
                  url: "/suppliers/chat",
                  method: "POST",
                  payload: email,
               });
            });
         })
      );

      return {
         successMessage: "Success",
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         error: error.response?.data.error,
      };
   }
}
