import { createSlice } from "@reduxjs/toolkit";
// Utils
import { status } from "@utils/constants";
// Types
import { IStates } from "./types";

const productList = createSlice({
   name: "productList",
   initialState: {
      isFloatingListOpen: false,
      status: status.IDLE,
      products: [],
      error: {} || "",
      code: "",
      total: 0,
      perPage: 0,
      page: 0,
   } as IStates,
   reducers: {
      productListClearCode: (state) => {
         state.code = "";
      },
      productListUpdateIsFloatingListOpen: (state, { payload }) => {
         state.isFloatingListOpen = payload;
      },
      productListRequest: (state, { payload }) => {
         state.status = status.PENDING;
         state.code = payload.code;
         state.error = {};
      },
      productListResolved: (state, { payload }) => {
         if (payload.products) {
            state.products = payload.products;
         }
         state.error = payload.error;
         state.code = payload.code;
         state.status = status.RESOLVED;
         state.total = payload.total;
         state.perPage = payload.perPage;
         state.page = payload.page;
      },
      productListRejected: (state, { payload }) => {
         state.status = status.REJECTED;
         state.code = payload.code;
         state.error = payload.error;
         if ("totalResult" in payload) {
            state.total = payload.totalResult;
         }
      },
   },
});

export const {
   productListRequest,
   productListResolved,
   productListRejected,
   productListClearCode,
   productListUpdateIsFloatingListOpen,
} = productList.actions;
export default productList.reducer;
