import { axios } from "@redux/store";
// Utils
import { stateType } from "../../../utils/constants";
// Types
import {
   THandleGetUserReponse,
   THandleGetUserLogsResponse,
   THandleEditUserResponse,
   THandleGetUsersResponse,
   THandleCreateUserReponse,
   THandleRemoveUserReponse,
   THandleResetPasswordReponse,
   THandleGetBranchReponse,
} from "./type";
import { AxiosError, AxiosResponse } from "axios";
import { TAccountManagementPayload } from "../request/types";
import { TUser, TError, TUsers, TBranchShape } from "../../slice/types";
// Uid
import uuid from "react-uuid";
// Saga
import { CallEffect, call } from "redux-saga/effects";

export function* handleGetUser({ username }: TAccountManagementPayload): Generator<
   CallEffect,
   {
      data: TUser;
      stateType: keyof typeof stateType;
      error?: TError;
   },
   never
> {
   try {
      const res: THandleGetUserReponse = yield call(() => {
         return axios({
            url: `/users/${username as string}`,
            method: "GET",
         });
      });

      return {
         data: res?.data,
         stateType: stateType.user,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         data: {},
         error: error.response?.data?.error,
         stateType: stateType.user,
      };
   }
}

export function* handleGetUserLogs({ username }: TAccountManagementPayload): Generator<
   CallEffect,
   {
      data: string;
      stateType: keyof typeof stateType;
      error?: TError;
   },
   never
> {
   try {
      const res: THandleGetUserLogsResponse = yield call(() => {
         return axios({
            url: `/users/${username as string}/logs`,
            method: "GET",
         });
      });

      return {
         data: res?.data?.url,
         stateType: stateType.downloadUserLogs,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         stateType: stateType.downloadUserLogs,
         error: error.response?.data?.error,
      };
   }
}

export function* handleEditUser({
   username,
   editUserData,
}: TAccountManagementPayload): Generator<
   CallEffect<AxiosResponse> | CallEffect<unknown>,
   {
      data?: string;
      stateType: keyof typeof stateType;
      error?: TError;
   },
   never
> {
   try {
      const res: THandleEditUserResponse = yield call(() => {
         return axios({
            url: `/users/${username as string}`,
            method: "PATCH",
            payload: editUserData,
         });
      });

      return {
         data: res.data?.message,
         stateType: stateType.editUser,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         error: error.response?.data?.error,
         stateType: stateType.editUser,
      };
   }
}

export function* handleGetUsers({
   currentUserId,
   page,
   perPage,
}: TAccountManagementPayload): Generator<
   CallEffect<AxiosResponse>,
   {
      data?: {
         items: TUsers[];
         perPage: number;
         page: number;
         total: number;
      };
      stateType: keyof typeof stateType;
      error?: TError;
   },
   never
> {
   try {
      const result: THandleGetUsersResponse = yield call(() => {
         return axios({
            url: `/users?page=${page || 1}&perPage=${perPage}`,
            method: "GET",
         });
      });

      const formattedResult = result.data.items?.reduce((a: TUsers[], c) => {
         if (c) {
            const obj: TUsers = {
               firstName: `${c.sub === currentUserId ? "<span>●</span>" : ""}${
                  c["firstName"]
               }`,
               lastName: c["lastName"],
               Email: c.email,
               userName: c.sub,
               id: uuid(),
            };
            a?.push(obj);
         }

         return a;
      }, []);

      return {
         data: {
            items: formattedResult,
            page: result.data.page,
            perPage: result.data.perPage,
            total: result.data.total,
         },
         stateType: stateType.users,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         stateType: stateType.users,
         error: error.response?.data?.error,
      };
   }
}

export function* handleCreateUser({ user }: TAccountManagementPayload): Generator<
   CallEffect,
   {
      data?: string;
      stateType: keyof typeof stateType;
      error?: TError;
   },
   never
> {
   try {
      const result: THandleCreateUserReponse = yield call(() => {
         return axios({
            url: "/users",
            method: "POST",
            payload: user,
         });
      });

      return {
         data: result?.data?.message,
         stateType: stateType.createUser,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         stateType: stateType.createUser,
         error: error.response?.data.error,
      };
   }
}

export function* handleRemoveUser({ username }: TAccountManagementPayload): Generator<
   CallEffect,
   {
      data?: string;
      stateType: keyof typeof stateType;
      error?: TError;
   },
   never
> {
   try {
      const res: THandleRemoveUserReponse = yield call(() => {
         return axios({
            url: `/users/${username as string}`,
            method: "DELETE",
         });
      });

      return {
         data: res.data?.message,
         stateType: stateType.removeUser,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         stateType: stateType.removeUser,
         error: error.response?.data.error,
      };
   }
}

export function* handleResetPassword({ username }: TAccountManagementPayload): Generator<
   CallEffect,
   {
      data?: string;
      stateType: keyof typeof stateType;
      error?: TError;
   },
   never
> {
   try {
      const res: THandleResetPasswordReponse = yield call(() => {
         return axios({
            url: `/users/${username as string}/reset-password`,
            method: "PATCH",
         });
      });
      return {
         data: res.data?.message,
         stateType: stateType.resetPassword,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         stateType: stateType.resetPassword,
         error: error.response?.data.error,
      };
   }
}

export function* handleGetBranches(): Generator<
   CallEffect,
   {
      data?: TBranchShape[];
      stateType: keyof typeof stateType;
      error?: TError;
   },
   never
> {
   try {
      const res: THandleGetBranchReponse = yield call(() => {
         return axios({
            url: "/branches",
            method: "GET",
            cacheKey: "branches",
            shouldExpire: false,
         });
      });
      return {
         data: res.data.items,
         stateType: stateType.branch,
      };
   } catch (err) {
      const error = err as AxiosError<{
         error: TError;
      }>;
      throw {
         stateType: stateType.branch,
         error: error.response?.data.error,
      };
   }
}
