import { createSlice } from "@reduxjs/toolkit";
// Types
import { IStates } from "./types";
// Utils
import { status } from "@utils/constants";

const emailBuyer = createSlice({
   name: "emailBuyer",
   initialState: {
      status: status.IDLE,
      items: [],
      successMessage: "",
      error: {
         type: "",
         message: "",
         data: {
            supplierId: "",
            productIds: [],
         },
      },
   } as IStates,
   reducers: {
      emailBuyerReset: (state) => {
         state.items = [];
         state.status = status.IDLE;
         state.successMessage = "";
         state.error = {};
      },
      emailBuyerUpdateItems: (state, { payload }) => {
         state.items = payload.items;
      },
      emailBuyerRequest: (state, {}) => {
         state.status = status.PENDING;
         state.error = {};
         state.successMessage = "";
      },
      emailBuyerResolved: (state, { payload }) => {
         state.status = status.RESOLVED;
         state.error = {};
         state.successMessage = payload.successMessage;
      },
      emailBuyerRejected: (state, { payload }) => {
         state.status = status.REJECTED;
         state.error = payload.error;
         state.successMessage = "";
      },
   },
});

export const {
   emailBuyerReset,
   emailBuyerUpdateItems,
   emailBuyerRequest,
   emailBuyerResolved,
   emailBuyerRejected,
} = emailBuyer.actions;
export default emailBuyer.reducer;
