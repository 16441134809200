import { AnyAction } from "@reduxjs/toolkit";
import { call, takeLatest } from "redux-saga/effects";
// Handlers
import { handleFetchProduct } from "../handler";
// Types
import { TProductPayload } from "./types";

function* getProduct({ payload }: AnyAction & { payload: TProductPayload }): unknown {
   yield call(handleFetchProduct, payload);
}

export default function* (): unknown {
   yield takeLatest("product/productRequest", getProduct);
}
