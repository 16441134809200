import { useEffect, useState } from "react";

export const useLocalStorage = <T>(
   key: string,
   fallBack: T
): [T, (value: T, onlyLocalStorage?: boolean) => void] => {
   const [state, setValue] = useState<T>(
      (JSON.parse(localStorage.getItem(key) as string) as T) || fallBack
   );

   const setState = (value: T, onlyLocalStorage = false): void => {
      localStorage.setItem(key, JSON.stringify(value));
      if (!onlyLocalStorage) {
         setValue(value);
         /**
          * We will dispatch the storage event so that all of the components
          * that are using this hook that have the same key will be notified.
          */
         window.dispatchEvent(new Event("useLocalStorage"));
      }
   };

   const updateStateWhenStorageUpdate = () => {
      const item = localStorage.getItem(key) || fallBack;

      if (item !== JSON.stringify(state)) {
         setValue(JSON.parse(item as string) as T);
      }
   };

   useEffect(() => {
      window.addEventListener("useLocalStorage", updateStateWhenStorageUpdate);

      return () => {
         window.removeEventListener("useLocalStorage", updateStateWhenStorageUpdate);
      };
   }, []);

   return [JSON.parse(JSON.stringify(state)) as T, setState];
};
