export const searchResultRequestType = {
   GET_SEARCH_RESULT: "GET_SEARCH_RESULT",
   GET_SEARCH_WAREHOUSES: "GET_SEARCH_WAREHOUSES",
   GET_FILTER_BRANCH: "GET_FILTER_BRANCH",
   GET_FILTER_PRICE: "GET_FILTER_PRICE",
   GET_FILTER_SUPPLIERS: "GET_FILTER_SUPPLIERS",
};

export const SEARCH_STATE_TYPE = {
   SEARCH: "search" as const,
   FILTER: "filter" as const,
};

export const searchFilterType = {
   BRANCH: "branch" as const,
   SUPPLIERS: "suppliers" as const,
   PRICE: "price" as const,
   WAREHOUSES: "warehouses" as const,
};
